<template>
  <svg
    :width="props.size"
    :height="props.size"
    class="loading-spinner-animation fill-current"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7.034C0 8.505.452 9.871 1.224 11A7.034 7.034 0 0 0 11 20.776 7.034 7.034 0 0 0 20.776 11 7.034 7.034 0 0 0 11 1.224a7.034 7.034 0 0 0-11 5.81Z"
    />
  </svg>
</template>

<script lang="ts" setup>
interface BaseLoadingSpinnerProps {
  size?: number | string
}

const props = withDefaults(defineProps<BaseLoadingSpinnerProps>(), { size: 22 })
</script>

<style lang="postcss" scoped>
.loading-spinner-animation {
  animation: loading-spinner 1000ms infinite;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  }
  50% {
    transform: rotate(90deg);
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
  }
  100% {
    transform: rotate(90deg);
    animation-timing-function: ease-out;
  }
}
</style>
